<template>
  <div>
    <!--begin::Head-->
    <div
      class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
    >
      <span class="font-weight-bold font-size-3 text-dark-60">
        Already have an account?
      </span>
      <router-link
        class="font-weight-bold font-size-3 ml-2"
        :to="{ name: 'login' }"
      >
        Sign In!
      </router-link>
    </div>
    <!--end::Head-->

    <!--begin::Body-->
    <div class="kt-login__body">
      <!--begin::Signin-->
      <div class="kt-login__form">
        <div class="text-center mb-10 mb-lg-20">
          <div>
            <a :href="siteUrl" class="flex-column-auto">
              <img
                src="media/logos/main_logo.jpg"
                class="h-6"
                style="max-height: 110px"
              />
            </a>
          </div>
          <h3 class="font-size-h1">Setup a New Password</h3>
        </div>

        <!--begin::Form-->
        <b-form class="kt-form" @submit.stop.prevent="onSubmit">
          <b-form-group
            id="example-input-group-5"
            label
            label-for="example-input-5"
          >
            <b-form-input
              class="form-control form-control-solid h-auto py-5 px-6"
              type="password"
              id="example-input-5"
              name="example-input-5"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="input-5-live-feedback"
              placeholder="Password"
            ></b-form-input>

            <b-form-invalid-feedback id="input-6-live-feedback"
              >Password is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group
            id="example-input-group-6"
            label
            label-for="example-input-6"
          >
            <b-form-input
              class="form-control form-control-solid h-auto py-5 px-6"
              type="password"
              id="example-input-6"
              name="example-input-6"
              v-model="$v.form.confirmPassword.$model"
              :state="validateState('confirmPassword')"
              aria-describedby="input-6-live-feedback"
              placeholder="Confirm Password"
            ></b-form-input>

            <b-form-invalid-feedback id="input-6-live-feedback"
              >Password should match .</b-form-invalid-feedback
            >
          </b-form-group>

          <!--begin::Action-->
          <div class="kt-login__actions">
            <a href="#" class="kt-link kt-login__link-forgot"></a>
            <b-button
              type="submit"
              id="kt_submit"
              class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
              >Reset Password</b-button
            >
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->
      </div>
      <!--end::Signin-->
      <vue-recaptcha
        ref="invisibleRecaptcha"
        :siteKey="sitekey"
        :loadRecaptchaScript="true"
        size="invisible"
      ></vue-recaptcha>
    </div>
    <!--end::Body-->
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import { mapState } from "vuex";

import ApiService from "@/core/services/api.service";

import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";

export default {
  components: { VueRecaptcha },
  mixins: [validationMixin],
  name: "register",
  data() {
    return {
      siteKey: process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY,
      siteUrl: "/",
      token: "",
      email: "",
      form: {
        password: "",
        confirmPassword: ""
      }
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(3)
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        password: null
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$refs.invisibleRecaptcha.execute();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const password = this.$v.form.password.$model;

      ApiService.post("admin/do-reset/password", {
        email: this.email,
        token: this.token,
        password: password
      }).then(response => {
        this.$snotify.success(response.data.message);
      });
      // dummy delay
      setTimeout(() => {
        this.$router.push({ name: "login" });
      }, 5000);
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-2.jpg";
    }
  },
  mounted: function() {},
  created: function() {
    this.token = this.$route.query.token ? this.$route.query.token : "";
    this.email = this.$route.query.email ? this.$route.query.email : "";

    ApiService.post("admin/check/reset/password", {
      token: this.token,
      email: this.email
    })
      .then()
      .catch(({ response }) => {
        this.$snotify.error(response.data.msg);
        setTimeout(() => {
          this.$router.push({ name: "forgot-password" });
        }, 5000);
      });
  }
};
</script>
